import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Html_tags from '../views/Html-tags.vue'
import Variable_css from '../views/Variables-css.vue'
import Js_tricks from '../views/Js-tricks.vue'
import Js_animations from '../views/Js-animations.vue'
import Php_functions from '../views/Php-functions.vue'
import Wordpress from '../views/Wordpress.vue'
import Librairies from '../views/Librairies.vue'
import Vue_js from '../views/Vuejs.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/html-tags',
    name: 'HTML tags',
    component: Html_tags
  },
  {
    path: '/variables-css',
    name: 'Variables CSS',
    component: Variable_css
  },
  {
    path: '/js-tricks',
    name: 'JS tricks',
    component: Js_tricks
  },
  {
    path: '/js-animations',
    name: 'JS animations',
    component: Js_animations
  },
  {
    path: '/php-functions',
    name: 'PHP functions',
    component: Php_functions
  },
  {
    path: '/wordpress',
    name: 'Wordpress',
    component: Wordpress
  },
  {
    path: '/librairies',
    name: 'Librairies',
    component: Librairies
  },
  {
    path: '/vue-js',
    name: 'VueJS',
    component: Vue_js
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
