<template>
    <button @click="copy_snippet" class="copy-circle" ref="click_me">
        <img src="../assets/svg/copy.svg">
    </button>
</template>

<script>
export default {
    methods: {
        copy_snippet(){
            let copy_circle = this.$refs.click_me;
            let code_snippet = copy_circle.previousElementSibling.textContent;
            navigator.clipboard.writeText(code_snippet)
                .then(() => { console.log("Copied") })
                .catch((error) => { console.log("Copy failed") })
        }
    }
}
</script>


<style>
.copy-circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    top: 1rem;
    left: 1rem;
    background: #fff;
    cursor: pointer;
    border: solid #06261B 1px;
    transition: all ease 0.4s;
}

.copy-circle img{
    width: 2rem;
    transition: all ease 0.4s;
}

.copy-circle:hover{
    background: rgb(229, 228, 229);
}

.copy-circle:hover img{
    transform: scale(1.1);
}
</style>

