<template>
    <section class="component-page-content">
        <router-view/>
    </section>
</template>

<script>
var all_copy_circle = document.querySelectorAll('.all-copy-circle');
//Opération sur tous les éléments de la classe 'my-item' (ex: retrait de la classe 'active')
all_copy_circle.forEach(function(allElements){
    allElements.classList.add('active');
});
</script>

<style>
    .component-page-content{
        width: 70%;
        margin-left: 30%;
        padding: 4rem;
    }
</style>