<template>
    <div class="main-container">
        <Menu />
        <PageContent />
    </div>
</template>



<script>
import Menu from './components/Menu.vue';
import PageContent from './components/PageContent.vue';

export default {
    components: {
        Menu,
        PageContent
    }
}    
</script>