<template>
    <section class="component-menu">
        <router-link to="/">
            <img class="main-logo" alt="Isidoor logo" src="../assets/svg/logo-isidoor.svg">
        </router-link>
        <nav class="left-menu">

            <div class="dropdown-container">
                <router-link to="/html-tags" class="menu-link">HTML tags</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Tags</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/variables-css" class="menu-link">Variables CSS</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Variables</li>
                    <li class="submenu-link">Typographie</li>
                    <li class="submenu-link">Responsive</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/js-tricks" class="menu-link">JS-tricks</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Sélection d'un élément</li>
                    <li class="submenu-link">Opérations sur les classes</li>
                    <li class="submenu-link">Évènements</li>
                    <li class="submenu-link">Évènements sur une boucle</li>
                    <li class="submenu-link">Delay</li>
                    <li class="submenu-link">Mise à jour d'un tableau</li>
                    <li class="submenu-link">Responsive</li>
                    <li class="submenu-link">Mutations</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/js-animations" class="menu-link">JS-animations</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Détection si un élément est dans le viewport</li>
                    <li class="submenu-link">Détection si un élément est dans le viewport 2</li>
                    <li class="submenu-link">Parallax</li>
                    <li class="submenu-link">Dropdown accordéon</li>
                    <li class="submenu-link">On-Scroll revealer</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/php-functions" class="menu-link" @click="display_php_menu">PHP-functions</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Formulaire - Réception des données</li>
                    <li class="submenu-link">Validation reCAPTCHA</li>
                    <li class="submenu-link">Envoi des données par courriel</li>
                    <li class="submenu-link">Lecture / écriture des données dans une DB</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/wordpress" class="menu-link">Wordpress</router-link>
                <ul class="submenu">
                    <li class="submenu-link">Page Options</li>
                    <li class="submenu-link">Custom Post Type</li>
                    <li class="submenu-link">Setup REST API</li>
                    <li class="submenu-link">Add custom scripts</li>
                    <li class="submenu-link">Fil d'Ariane</li>
                    <li class="submenu-link">Search</li>
                    <li class="submenu-link">WP Query</li>
                </ul>
            </div>

            <div class="dropdown-container">
                <router-link to="/librairies" class="menu-link">Librairies</router-link>
                <ul class="submenu">
                    <li class="submenu-link">CustomRevealAnimation</li>
                    <li class="submenu-link">AOS</li>
                </ul>
            </div>

            <br>
            <router-link to="/vue-js" class="menu-link">VueJS</router-link>
        </nav>
    </section>
</template>


<script>
    export default {
        methods:{
            start_function(){
                this.$nextTick(() => {
                    var dropdown_menu_links = document.querySelectorAll('.dropdown-container .menu-link');
                    for(var i = 0; i < dropdown_menu_links.length; i++) {
                        dropdown_menu_links[i].addEventListener('click', function(){
                            var parent = this.parentElement;
                            var article = this.nextElementSibling;
                            if (!parent.classList.contains('open')) {
                                parent.classList.add('open');
                                article.style.maxHeight = article.scrollHeight + 'px';
                            }
                            else {
                                parent.classList.remove('open');
                                article.style.maxHeight = '0px';
                            }
                        });
                    }
                })
            }
        },
        mounted() {
            this.start_function();
        }
    }
</script>

<style>
    .component-menu{
        position: fixed;
        width: 25%;
        padding: 4rem;
    }

    .main-logo{
        width: 35rem;
    }

    .left-menu{
        display: flex;
        flex-direction: column;
        padding-top: 5rem;
    }

    .menu-link{
        display: block;
        position: relative;
        font-family: "Work Sans";
        padding: 0.8rem 1rem 0.8rem 2rem;
        color: #c1c1c1;
        font-size: 2rem;
        text-decoration: none;
        transition: all ease 0.4s;
    }

    .menu-link::before{
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        width: 3px;
        height: 3px;
        transform: translateY(-50%);
        background: #c1c1c1;
        border-radius: 50%;
        transition: all ease 0.4s;
    }

    .menu-link:hover{
        color: #fff;
    }

    .menu-link.router-link-exact-active{
        color: #fff;
        padding-left: 3.5rem;
    }

    .menu-link.router-link-exact-active::before{
        width: 1.5rem;
        border-radius: 15px;
    }

    .submenu{
        margin: 0;
        padding: 0;
        margin-left: 1.5rem;
    }

    .submenu-link{
        display: block;
        position: relative;
        font-family: "Work Sans";
        padding: 0.6rem 1rem 0.6rem 2rem;
        color: #c1c1c1;
        font-size: 1.6rem;
        text-decoration: none;
        transition: all ease 0.4s;
    }

    .submenu-link:hover{
        color: #fff;
        cursor: pointer;
    }

    /********** Transitions **********/
    /*.slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all ease 0.4s;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(-1rem);
        opacity: 0;
    }*/

    .dropdown-container .submenu {
        max-height: 0px;
        overflow: hidden;
        transition: all ease 0.4s;
    }
</style>